import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../shared/translation.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({
  name: 'translate',
  standalone: true,
  pure: false,
})
export class TranslatePipe implements PipeTransform {

  constructor(private translationService: TranslationService) {}

  transform(value: string): string {
    const translations = this.translationService.getTranslations();
    return translations[value] || value;
  }
}
